@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;1,200;1,300;1,400;1,600;1,700&display=swap");

@font-face {
  font-family: dotpfont;
  src: url(../fonts/Montserrat-Bold.ttf);
  font-weight: bold;
}


@font-face {
  font-family: inter;
  src: url(../fonts/Inter-Medium.ttf);
  font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
  transition: 0.5s ease-in !important;
}


@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth !important;
  }
}

@media print {
  :root {
    display: none !important;
    pointer-events: none !important;
  }
}


body {
  background-color: #000 !important;
  overscroll-behavior-y: contain;
}


p {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  font-family: "inter", sans-serif;
  line-height: 2;
}


h1 {
  font-family: dotpfont !important;
}



.topnav {

  background-color: #fff !important;
  border-radius: 0rem 0rem 1rem 1rem;

}


.topnav .txt{

  margin-left: 0.6rem !important;
}


.sib-conversations--mobile-widget:not(.sib-conversations--expanded), .sib-conversations--mobile-widget:not(.sib-conversations--expanded) * {
  display: none !important;
}


.sib-conversations--side-right.sib-conversations--transparent, .sib-conversations--pos-right.sib-conversations--transparent {
  margin: 1.4rem !important;
}


.card {

  background-color: #222;
  border-radius: 0.5rem;
  cursor: pointer;

}


.bg-btn {

  background: #121212 !important;
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
}


.bg-btn:active {

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}


pre {

  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}


hr {

  color: white;
  text-align: center !important;
  align-self: center !important;
  justify-self: center !important;
}



.bg-dark {

  background-color: #111 !important;
}


.theme-dark {

  background-color: #222 !important;
}

.ico-inactive {

  opacity: 0.4 !important;
  cursor: pointer;
}


.fa-fade {

  animation-duration: var(--fa-animation-duration,2s) !important;
}



footer, nav {

background: #222;

}


.read .card {

  height: 20vh;
}

.fa-bounce {

  animation-duration: var(--fa-animation-duration,2s) !important;
}



.signupbtnn {

  width: 100% !important;
  background-color: #121212 !important;
  
}


.signupbtnn:active {

  border: none !important;
}


.profile .signupbtnn {

  width: 30% !important;
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
  border-radius: 5rem !important;
  
}

.profile .signupbtnn small {

  color: #000 !important;
}


input:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  border: none !important;
}


.otp {

  font-size: .875em !important;
  cursor: pointer;
}

.otp span {

  color: #fff !important;
}


.Toastify__toast {

  min-height: 1rem !important;
  padding: 3px !important;
  height: 5rem !important;
}

.Toastify__toast-body > div:last-child {

  text-align: center !important;

}

.Toastify__close-button > svg {

  display: none !important;

}

.Toastify__toast-theme--dark {

  border: none !important;
  border-radius: 1.5rem !important;
  background-color: #FBCF00 !important;
  color: #000 !important;
  margin: 0.5rem !important;
}


.Toastify__toast-theme--info {

  border: none !important;
  border-radius: 1.5rem !important;
  background-color: #FBCF00 !important;
  color: #000 !important;
  margin: 0.5rem !important;
}


.Toastify__toast-theme--danger {

  border: none !important;
  border-radius: 1.5rem !important;
  background-color: red !important;
  color: #fff !important;
  margin: 0.5rem !important;
}

.nav {

  background-color: #fff !important;
  border-radius: 1.5rem 1.5rem 0rem 0rem !important; 
}


.nav img{

  cursor: pointer !important;
}

.prof {

  border-radius: 50% !important;
}

.wallet .card{

  background-color: #fff !important;
  border-radius: 1rem !important;

}

.wallet .card span{

  font-size: 1.4rem !important;
}

.wallet .card p{

  color: #000 !important;
}

.wallet .card .notes{

  font-size: 1rem !important;

}

.active{
  opacity: 1 !important;
}

.inactive {

  opacity: 0.35 !important;
}

.qtools .card {

  background-color: #121212  !important;
  border: none !important;
  color: #fff !important;
}

.qtools input {

  border-radius: 5rem !important;
}


.dicover .card {
  background-color: #121212 !important;
  color: #fff !important;
  border-radius: 1rem !important;
}


.dicover .card small {

  color: #a1a1a1 !important;

}



.dicover small {

  color: #a1a1a1 !important;

}


.profile small {

  color: #a1a1a1 !important;
}

.version small {

  color: #a1a1a1 !important;
}


.react-pdf__Page__canvas {

  width: 100% !important;
  height: 0% !important;
}

.react-pdf__Page__textContent textLayer{

  display: none !important;
}

.dicover .card input{

  background-color: transparent !important;
  border: 1px solid #222222 !important;
  color: #fff !important;

}

.dicover .card label small{

  color: #fff !important;
}

.dicover .card select{

  background-color: transparent !important;
  border: 1px solid #222222 !important;
  color: #fff !important;

}

.dicover .card option{

  background-color: #000 !important;
  border: none !important;
  color: #fff !important;

}

.dicover button {

  background-color: #121212 !important;
  color: #fff !important;
}


.icon {

  font-size: 3rem !important;
}


.dicover .card [type="file"] {
  /* Style the color of the message that says 'No file chosen' */
    color: #878787 !important;
  }

  .dicover .card  [type="file"]::-webkit-file-upload-button {
    background: #fff;
    border: none !important;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    font-size: 12px;
    outline: none;
    padding: 8px 10px;
    transition: all 1s ease;
  }
  
  .dicover .card [type="file"]::-webkit-file-upload-button:hover {
    background: #888888;
    border: 2px solid #535353;
    color: #000;
  }


.dicover .card .previewnote {

  height: 100vh !important;
}


#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
  
.chatr {

  background-color: #121212 !important;
  border-radius: 1.1rem 1.1rem 0rem 0rem !important; 
}

.cs-message-input__tools:first-child .cs-button:last-child {

  display: none !important;
}

.cs-button--send {
  color: #fff !important;
}

.cs-message-input__content-editor-wrapper {

  background: #121212 !important;
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
}

.cs-message__content-wrapper {

  width: 100% !important;

}

.cs-message-input__content-editor {

  background-color: transparent !important;
  color: #fff !important

}

.cs-message-input__content-editor-container {

  background-color: transparent !important;
}


.cs-message-input__content-editor[data-placeholder]:empty:before {

  color: #a1a1a1 !important;

}

.cs-message-list {

  background-color: transparent !important;
}

.cs-message__content {

  background: #222 !important;
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
  color: #fff !important;
  padding: 1.4rem !important;
  border-radius: 0.7em 2em 0.7em 0.7em !important;
}

.cs-message--outgoing .cs-message__content {

  margin-top: 1.4rem !important;
  margin-bottom: 1.4rem !important;
  border-radius: 2em 0.7em 0.7em 0.7em !important;
}

.cs-message-list .cs-typing-indicator {

  background-color: transparent !important;
  
}

.cs-typing-indicator__text {

  color: #a1a1a1 !important; 

}

.scrollbar-container {

  touch-action: auto !important;

}

/***** End of Animations ****/

/********** RESPONSIVENESS **********/

/* For 480 Resolution - Laptop*/
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* STYLES GO HERE */

    .signupbtnn {

    width: 100% !important;
    background-color: #121212 !important;
  }

  .read .card {

    height: 18vh;
  }

  .Toastify__toast-theme--dark {

    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
  
  
  .Toastify__toast-theme--info {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
  
  
  .Toastify__toast-theme--danger {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: red !important;
    color: #fff !important;
    margin: 1.2rem !important;
  }
}

/* For 640 Resolution - mobile */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {

  .read .card {

    height: 18vh;
  }

  .Toastify__toast-theme--dark {

    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
  
  
  .Toastify__toast-theme--info {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
  
  
  .Toastify__toast-theme--danger {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: red !important;
    color: #fff !important;
    margin: 1.2rem !important;
  }


}

/* For 1024 Resolution - laptop 1 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
 
}

/* For 1366 Resolution  -- laptop 2*/
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  /* STYLES GO HERE */
}

/** desktop screen */
@media only screen and (min-width: 1370px) and (max-width: 1605px) {
  /* STYLES GO HERE */
}

/*Ipad Orientation : Landscape */
@media only screen and (orientation: landscape) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
  /* STYLES GO HERE */
}

/*ipad Orientation : Portrait */
@media only screen and (orientation: portrait) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
  /* STYLES GO HERE */
}